<template>
  <section id="firstBanner" class="bg-black">
    <b-container class="topLogoContainer" fluid>
      <div class="imageBg" :style="{ minHeight: firstBannerTotalH + 'px' }">
        <img
          :src="fullScreenImageSource"
          alt="Dundas West"
          class="full-screen-image"
        />
      </div>
      <div class="upperLayout" :style="{ minHeight: firstBannerH + 'px' }">
        <div class="header-text text-yellow FoundersGrotesk">
          <div class="center-vertically">
            ODE IS A PLACE TO STAY ON DUNDAS WEST.
          </div>
        </div>
      </div>
    </b-container>
  </section>
</template>

<script>
import jq from "jquery";
export default {
  data() {
    return {
      firstBannerH: null,
      firstBannerTotalH: null,
      fullScreenImageSource: "/img/home/DundasWest_Hero.jpg",
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.getMidHeight();
      jq(window).on("resize", () => {
        this.getMidHeight();
        setTimeout(() => {
          this.getMidDomHeight();
        }, 500);
      });
      setTimeout(() => {
        this.getMidDomHeight();
      }, 100);
    });
  },
  methods: {
    getMidHeight() {
      this.firstBannerH = jq(window).height();
    },
    getMidDomHeight() {
      this.firstBannerTotalH = jq("#firstBanner").height();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./assets/styles/variables";
#firstBanner {
  overflow: hidden;
  .topLogoContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    .imageBg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100vh; /* Full-screen height */
      overflow: hidden;
      .full-screen-image {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .upperLayout {
      width: 100%;
      min-height: 80vh;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      position: relative;
      .center-vertically {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 50%;
        transform: translateX(-50%);
        text-align: center;
        width: 100%;
        @media screen and (max-width: 500px) {
          width: 70%;
        }
      }
      .m-logo {
        position: absolute;
        top: 0px;
        @media screen and (max-width: 450px) {
          max-width: 180px;
        }
      }
    }
  }
}
</style>
